<template>
  <div class="indicate-main" v-if="showBar">
    <div class="indicate-div-main">
      <div class="submitButton">
        <van-button round type="info" @click="goSubmit" v-if="endQuestion">
          <span class="sub">交 卷</span>
        </van-button>
      </div>
      <div class="indicate-num" @click="openDialog">
        <div class="error-num indicate-num-item" v-if="trainning">
          <img class="answer-img" src="@/assets/images/false.png" alt="" srcset="" />
          <span>{{ errorNum }}</span>
        </div>
        <div class="success-num indicate-num-item" v-if="trainning">
          <img class="answer-img" src="@/assets/images/true.png" alt="" srcset="" />
          <span>{{ successNum }}</span>
        </div>
        <div class="success-num indicate-num-item">
          <img class="answer-img" src="@/assets/images/menu.png" alt="" srcset="" />
          <span>{{ questionIndex + 1 }}/{{ questionList.length }}</span>
        </div>
      </div>
    </div>
    <van-popup v-model="show" :close-on-popstate="true" position="bottom" :style="{ height: '70%' }">
      <!-- <AnswerPage class="large-content-main" :question="largerModuleQuestion" /> -->
      <div>
        <div class="indicate-div-main">
          <div></div>
          <div class="indicate-num" @click="openDialog">
            <div class="error-num indicate-num-item" v-if="trainning">
              <img class="answer-img" src="@/assets/images/false.png" alt="" srcset="" />
              <span>{{ errorNum }}</span>
            </div>
            <div class="success-num indicate-num-item" v-if="trainning">
              <img class="answer-img" src="@/assets/images/true.png" alt="" srcset="" />
              <span>{{ successNum }}</span>
            </div>
            <div class="success-num indicate-num-item">
              <img class="answer-img" src="@/assets/images/menu.png" alt="" srcset="" />
              <span>{{ questionIndex + 1 }}/{{ questionList.length }}</span>
            </div>
          </div>
        </div>
        <div class="indicate-content" v-for="(item, index) in questionRuleDtoListLocal" :key="index">
          <div class="indicate-title">{{ item['questionName'] }}</div>
          <div class="indicate-list">
            <div
              @click="goQuestion(questionItem)"
              class="indicate-list-item"
              :class="{
                'indicate-list-item-success': questionItem['resultAccuracy'] === 'success',
                'indicate-list-item-error': questionItem['resultAccuracy'] === 'error',
                'indicate-list-item-wait': questionItem['resultAccuracy'] === 'wait',
              }"
              v-for="(questionItem, questionItemIndex) in item['questionList']"
              :key="questionItem['id']"
            >
              {{ questionItemIndex + 1 }}
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'PopupQuestionList',
  components: {},
  props: {},
  data() {
    return {
      showBar: false,
      show: false,
      questionRuleDtoListLocal: [],
      errorNum: 0,
      successNum: 0,
    }
  },
  computed: {
    ...mapState(['answerList', 'questionList', 'questionIndex', 'questionRuleDtoList', 'endQuestion', 'trainning']),
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.show = false
        console.log('watch---->', val)
        if (['/practice', '/simulation'].includes(val['path'])) {
          this.showBar = true
        } else {
          this.showBar = false
        }
      },
      immediate: true,
    },
    questionRuleDtoList: {
      handler: function (val, oldVal) {
        this.questionRuleDtoListLocal = val
      },
      immediate: true,
    },
    questionRuleDtoListLocal: {
      handler: function (val, oldVal) {
        if (this.trainning) {
          let errorNum = 0
          let successNum = 0
          val.forEach((item) => {
            item['questionList'].forEach((questionItem) => {
              if (questionItem['resultAccuracy'] === 'error') {
                errorNum++
              } else if (questionItem['resultAccuracy'] === 'success') {
                successNum++
              }
            })
          })
          this.errorNum = errorNum
          this.successNum = successNum
        }
      },
      immediate: true,
    },
    answerList: {
      handler: function (val, oldVal) {
        console.log('watch---->answerList', val)
        this.questionRuleDtoListLocal = this.questionRuleDtoListLocal.map((item) => {
          let questionList = item['questionList'].map((questionItem) => {
            // if (!!questionItem['resultAccuracy']) {
            //   return questionItem
            // }
            let existingItem = val.find((answer) => answer.questionId === questionItem['id'])
            if (existingItem) {
              //   console.log('existingItem--->', existingItem)
              let questionItem = this.questionList.find((question) => question.id === existingItem.questionId)
              if (!this.trainning) {
                return {
                  ...existingItem,
                  ...questionItem,
                  resultAccuracy: 'wait',
                }
              } else {
                if (existingItem['questionType'] == 3) {
                  return {
                    ...existingItem,
                    ...questionItem,
                    resultAccuracy: 'wait',
                  }
                } else {
                  if (questionItem['questionAnswer'] === existingItem['answerContent']) {
                    return {
                      ...questionItem,
                      ...existingItem,
                      resultAccuracy: 'success',
                    }
                  } else {
                    return {
                      ...questionItem,
                      ...existingItem,
                      resultAccuracy: 'error',
                    }
                  }
                }
              }
            } else {
              return questionItem
            }
          })
          return {
            ...item,
            questionList,
          }
        })
      },
      immediate: true,
      deep: true,
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  methods: {
    ...mapMutations([]),
    openDialog() {
      this.show = true
    },
    goQuestion(item) {
      //   console.log('goQuestion--->', item)
      this.$EventBus.$emit('swiperGo', item)
      this.show = false
    },
    goSubmit() {
      this.$EventBus.$emit('examSubmit')
    },
  },
  beforeDestroy() {},
  destroyed() {},
}
</script>
<style scoped lang="less" type="text/less">
.indicate-main {
  z-index: 3;
  position: fixed;
  bottom: 5px;
  left: 0;
  right: 0;
}
.indicate-div-main {
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // align-items: center;
  .submitButton {
    margin: 0 5px;
    .sub {
      padding: 0 10px;
    }
    /deep/ .van-button {
      height: 30px !important;
    }
  }
  .indicate-num {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    //   align-items: center;
    .indicate-num-item {
      // height: 20px;
      // line-height: 20px;
      display: flex;
      flex-direction: row;

      // justify-content: flex-end;
      align-items: center;
      // margin-right: 10px;
      // text-align: center;
      // align-items: center;
      margin-right: 10px;
    }
    .answer-img {
      width: 20px;
      height: 20px;
    }
  }
}
.indicate-content {
  font-size: 15px;
  padding: 5px;
  //   overflow-y: scroll;
  .indicate-title {
    background-color: #ebeef5;
    margin-bottom: 10px;
  }
  .indicate-list {
    // display: flex;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
    .indicate-list-item {
      background-color: #f5f7fa;
      flex: 1 0 20%;
      border: 1px solid #dcdfe6;
      border-radius: 50%;
      text-align: center;
      width: 13vw;
      height: 13vw;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .indicate-list-item-success {
      background-color: #409eff;
      color: white;
    }
    .indicate-list-item-error {
      background-color: #f56c6c;
      color: white;
    }
    .indicate-list-item-wait {
      background-color: #e6a23c;
      color: white;
    }
  }
}
</style>
