var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showBar
    ? _c(
        "div",
        { staticClass: "indicate-main" },
        [
          _c("div", { staticClass: "indicate-div-main" }, [
            _c(
              "div",
              { staticClass: "submitButton" },
              [
                _vm.endQuestion
                  ? _c(
                      "van-button",
                      {
                        attrs: { round: "", type: "info" },
                        on: { click: _vm.goSubmit },
                      },
                      [_c("span", { staticClass: "sub" }, [_vm._v("交 卷")])]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "indicate-num", on: { click: _vm.openDialog } },
              [
                _vm.trainning
                  ? _c("div", { staticClass: "error-num indicate-num-item" }, [
                      _c("img", {
                        staticClass: "answer-img",
                        attrs: {
                          src: require("@/assets/images/false.png"),
                          alt: "",
                          srcset: "",
                        },
                      }),
                      _c("span", [_vm._v(_vm._s(_vm.errorNum))]),
                    ])
                  : _vm._e(),
                _vm.trainning
                  ? _c(
                      "div",
                      { staticClass: "success-num indicate-num-item" },
                      [
                        _c("img", {
                          staticClass: "answer-img",
                          attrs: {
                            src: require("@/assets/images/true.png"),
                            alt: "",
                            srcset: "",
                          },
                        }),
                        _c("span", [_vm._v(_vm._s(_vm.successNum))]),
                      ]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "success-num indicate-num-item" }, [
                  _c("img", {
                    staticClass: "answer-img",
                    attrs: {
                      src: require("@/assets/images/menu.png"),
                      alt: "",
                      srcset: "",
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.questionIndex + 1) +
                        "/" +
                        _vm._s(_vm.questionList.length)
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _c(
            "van-popup",
            {
              style: { height: "70%" },
              attrs: { "close-on-popstate": true, position: "bottom" },
              model: {
                value: _vm.show,
                callback: function ($$v) {
                  _vm.show = $$v
                },
                expression: "show",
              },
            },
            [
              _c(
                "div",
                [
                  _c("div", { staticClass: "indicate-div-main" }, [
                    _c("div"),
                    _c(
                      "div",
                      {
                        staticClass: "indicate-num",
                        on: { click: _vm.openDialog },
                      },
                      [
                        _vm.trainning
                          ? _c(
                              "div",
                              { staticClass: "error-num indicate-num-item" },
                              [
                                _c("img", {
                                  staticClass: "answer-img",
                                  attrs: {
                                    src: require("@/assets/images/false.png"),
                                    alt: "",
                                    srcset: "",
                                  },
                                }),
                                _c("span", [_vm._v(_vm._s(_vm.errorNum))]),
                              ]
                            )
                          : _vm._e(),
                        _vm.trainning
                          ? _c(
                              "div",
                              { staticClass: "success-num indicate-num-item" },
                              [
                                _c("img", {
                                  staticClass: "answer-img",
                                  attrs: {
                                    src: require("@/assets/images/true.png"),
                                    alt: "",
                                    srcset: "",
                                  },
                                }),
                                _c("span", [_vm._v(_vm._s(_vm.successNum))]),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "success-num indicate-num-item" },
                          [
                            _c("img", {
                              staticClass: "answer-img",
                              attrs: {
                                src: require("@/assets/images/menu.png"),
                                alt: "",
                                srcset: "",
                              },
                            }),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.questionIndex + 1) +
                                  "/" +
                                  _vm._s(_vm.questionList.length)
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _vm._l(_vm.questionRuleDtoListLocal, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "indicate-content" },
                      [
                        _c("div", { staticClass: "indicate-title" }, [
                          _vm._v(_vm._s(item["questionName"])),
                        ]),
                        _c(
                          "div",
                          { staticClass: "indicate-list" },
                          _vm._l(
                            item["questionList"],
                            function (questionItem, questionItemIndex) {
                              return _c(
                                "div",
                                {
                                  key: questionItem["id"],
                                  staticClass: "indicate-list-item",
                                  class: {
                                    "indicate-list-item-success":
                                      questionItem["resultAccuracy"] ===
                                      "success",
                                    "indicate-list-item-error":
                                      questionItem["resultAccuracy"] ===
                                      "error",
                                    "indicate-list-item-wait":
                                      questionItem["resultAccuracy"] === "wait",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goQuestion(questionItem)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(questionItemIndex + 1) + " "
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }